// import CookieStorage from "~/services/CookieStorage";

import { defineStore } from "pinia";
import { getDeviceType } from 'user-device-type'

export const useUrlBuilder = defineStore({
  id: "url-builder",
  state: () => {
    return {
      userAgent: null,
      xVpAppsProjectId: 'be5EWAxM7Og', // Z6wMB4kEl2n //geb-----> 'dV8MmwVEG2X',//' app-84---->JkvEjBQEXV6',be5EWAxM7Og, gazi-R4VE4vxmMGv
      xVpAppsDomain: 'app-23.prod.vpapps.cloud', //' app-122.vpapps.cloud',
      fullPath: null,
      testHeaderAcceptLanguage: null,
      TESTxVpAppsProjectId: 'testproject',
      TESTxVpAppsDomain: 'testdomain',
      token: null,
      config: null,
      biskoId: null,
      userDeviceType: null,
    }
  },
  actions: {
    actionStoreBiskoIdFromCookie(data) {
      this.biskoId = data
    },
    actionStoreAccessTokenFromCookie(data) {
      if (data) {
        this.token = data
      }
    },
    actionStoreRequestHeaders(data) {
      if (data["referer"]) {
        this.fullPath = data["referer"];
      }

      const useAgent = data["user-agent"]
      if (useAgent) {
        this.userAgent = useAgent;
        this.userDeviceType = getDeviceType()
      }

      if (data["x-vp-apps-domain"]) {
        this.xVpAppsDomain = data["x-vp-apps-domain"];
      }

      if (data["x-vp-apps-project-id"]) {
        this.xVpAppsProjectId = data["x-vp-apps-project-id"];
      }

      if (data["accept-language"]) {
        this.testHeaderAcceptLanguage = data["accept-language"];
      }

      if (data["x-vp-apps-domain"]) {
        this.TESTxVpAppsDomain = data["x-vp-apps-domain"];
      }

      if (data["x-vp-apps-project-id"]) {
        this.TESTxVpAppsProjectId = data["x-vp-apps-project-id"];
      }
    },
    actionSetToken(data) {
      this.token = data;
    },
    actionRemoveTokenFromStore() {
      this.token = null
    },
    actionSetConfig() {
      const config = useRuntimeConfig();

      this.config = config
    }
  },
  getters: {
    getRequestHeaders: (state) => {

      // const cookie = new CookieStorage()
      // const accessToken = cookie.getItem('auth._access_token.iodc')
      // const accessToken = useCookie("auth._access_token.iodctesttt");
      // const userLogOut = useCookie("user-logged-out");
      return {
        "X-Project-Id": state.xVpAppsProjectId,
        "X-Project-Domain": state.xVpAppsDomain,
        testHeaderAcceptLanguage: state.testHeaderAcceptLanguage,
        ...(state.token ? { authorization: `Bearer ${state.token}` } : {}),
        ...(state.biskoId ? { "X-Bisko-Id": state.biskoId } : {}),
      };
    },
    getProjectId: (state) => state.xVpAppsProjectId,
    getProjecDomain: (state) => `https://${state.xVpAppsDomain}`,
    getToken: (state) => state.token,
    isCLH: (state) => state.userAgent?.includes("Chrome-Lighthouse") ?? false,
  },
});
